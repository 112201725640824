<template>
  <div class="h-100 d-flex flex-column">
    <router-view name="header"></router-view>
    <router-view name="context" class="flex-fill text-left pms-body-context"></router-view>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },

    created(){
      this.$store.dispatch("Public/getMyLocation");
    }
  };

</script>

<style lang='scss'>
  @import '../assets/scss/variables';
  @import '../assets/scss/mixin';

  .pms-body-context {
    height: calc(100% - 3.8rem);
    position: relative;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
  }

</style>
